import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"large-alert text-center fill-height"},[_c(VDialog,{attrs:{"fullscreen":""},model:{value:(_vm.showSetupDialog),callback:function ($$v) {_vm.showSetupDialog=$$v},expression:"showSetupDialog"}},[_c(VCard,{staticClass:"fill-height",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Scanner Setup "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","outlined":""},on:{"click":function($event){_vm.showSetupDialog=false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{},[_vm._v(" To setup QR scanner, scan the following codes. "),_c(VRow,{staticClass:"text-center"},[_c(VCol,[_vm._v("Code 1 "),_c('qrcode-vue',{attrs:{"value":"%%SpecCodeAA","size":70}})],1),_c(VCol,[_vm._v("Code 2 "),_c('qrcode-vue',{attrs:{"value":"%%SpecCode99","size":70}})],1)],1),_vm._v(" After scanning the above codes, the scanner will flash blue so you can pair with tablet device. When scanner light is solid blue, it is ready to go. ")],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){_vm.showSetupDialog=false}}},[_vm._v("Close")])],1)],1)],1),_c(VContainer,{staticStyle:{"height":"100%"}},[(!_vm.mealScanned)?_c('h1',{attrs:{"type":"info","x-large":""}},[_vm._v("Scan Meal")]):_vm._e(),(_vm.mealScanned && !_vm.binScanned)?_c('h1',{attrs:{"type":"warning","x-large":""}},[_vm._v("Scan Bin "+_vm._s(_vm.mealScanned))]):_vm._e(),(_vm.mealScanned && _vm.binScanned && _vm.mealScanned===_vm.binScanned)?_c('h1',{attrs:{"type":"success","x-large":""}},[_vm._v("Correct! ")]):_vm._e(),(_vm.mealScanned && _vm.binScanned && _vm.mealScanned!==_vm.binScanned)?_c('h1',{attrs:{"type":"error","x-large":""}},[_vm._v("WRONG BIN! ")]):_vm._e(),_c('qrcode-stream',{staticStyle:{"height":"80%","width":"80%"},attrs:{"track":_vm.paintBoundingBox},on:{"detect":_vm.onDetect,"error":_vm.onError}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }